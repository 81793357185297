<div class="addContainer">
    <div class="modalHeader">
        <div class="editRowModal">
            <div class="modalHeader clearfix">
                <!-- <img [src]='doctors.img' alt="avatar"> -->
                <div class="modal-about" *ngIf="pharmacyCategory.action == 'add'">
                    <div class="font-weight-bold p-t-5 font-17">
                        {{languagePharmacy.addcategory | translate}}</div>
                </div>
                <div class="modal-about" *ngIf="pharmacyCategory.action == 'edit'">
                    <div class="font-weight-bold p-t-5 font-17">
                        {{languagePharmacy.updatecategory | translate}}</div>
                </div>
            </div>
        </div>
        <button mat-icon-button (click)="dialogRef.close()" aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div mat-dialog-content>
        <form class="register-form m-6" [formGroup]="pharmacyCategoryForm" (ngSubmit)="submit">
            <mat-form-field class="example-full-width" appearance="outline" style="width: 500px;">
                <mat-label>{{languagePharmacy.selectcategory | translate}}</mat-label>
                <mat-select formControlName="category">
                    <mat-option value="1">การบริการผู้สูงอายุ</mat-option>
                    <mat-option value="2">เรียกรถรับส่ง</mat-option>
                </mat-select>
            </mat-form-field>
            <!-- <mat-form-field class="example-full-width" appearance="outline" style="width: -webkit-fill-available;">
                <mat-label>{{languagePharmacy.categorynamethai | translate}}</mat-label>
                <input matInput formControlName="categoryTH" required>
                <mat-icon matSuffix>account_circle</mat-icon>
                <mat-error *ngIf="pharmacyCategoryForm.get('categoryTH').hasError('required')">
                    category name is required
                </mat-error>
            </mat-form-field>

            <mat-form-field class="example-full-width" appearance="outline" style="width: -webkit-fill-available;">
                <mat-label>{{languagePharmacy.categorynameenglish | translate}}</mat-label>
                <input matInput formControlName="categoryEN" required>
                <mat-icon matSuffix>account_circle</mat-icon>
                <mat-error *ngIf="pharmacyCategoryForm.get('categoryEN').hasError('required')">
                    category name is required
                </mat-error>
            </mat-form-field> -->

            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
                    <div class="example-button-row">
                        <button mat-raised-button color="primary" *ngIf="pharmacyCategory.action == 'add'"
                            [type]="submit" [disabled]="!pharmacyCategoryForm.valid">{{languagePharmacy.save |
                            translate}}</button>
                        <!-- (click)="confirmAddCategory(pharmacyCategory)" -->
                        <button mat-raised-button color="primary" *ngIf="pharmacyCategory.action == 'edit'"
                            [type]="submit" [disabled]="!pharmacyCategoryForm.valid"
                            (click)="confirmAddCategory(pharmacyCategory)">{{languagePharmacy.update |
                            translate}}</button>
                        <button mat-raised-button color="warn" (click)="onNoClick()"
                            tabindex="-1">{{languagePharmacy.cancle | translate}}</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>