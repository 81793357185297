import { LanguageInterfaceAdmin } from "./language-interface-admin";

export const LanguageAdmin: LanguageInterfaceAdmin[] = [
  {
    dashboard: "ADMIN.DASHBOARD.DASHBOARD",
    home: "ADMIN.DASHBOARD.HOME",
    doctors: "ADMIN.DASHBOARD.DOCTORS",
    phamacies: "ADMIN.DASHBOARD.PHAMACIES",
    nutritionists: "ADMIN.DASHBOARD.NUTRITIONISTS",
    patients: "ADMIN.DASHBOARD.PATIENTS",
    doctorIncome: "ADMIN.DASHBOARD.DOCTOR-INCOME",
    pharmacyIncome: "ADMIN.DASHBOARD.PHARMACY-INCOME",
    nutritionistIncome: "ADMIN.DASHBOARD.NUTRITIONIST-INCOME",
    case: "ADMIN.DASHBOARD.CASE",
    order: "ADMIN.DASHBOARD.ORDER",
    today: "ADMIN.DASHBOARD.TODAY",
    thisWeek: "ADMIN.DASHBOARD.THIS-WEEK",
    thisMonth: "ADMIN.DASHBOARD.THIS-MONTH",
    doctorsIncomePerYear: "ADMIN.DASHBOARD.DOCTORS-INCOME-PER-YEAR",
    pharmaciesIncomePerYear: "ADMIN.DASHBOARD.PHARMACIES-INCOME-PER-YEAR",
    nutritionistsIncomePerYear: "ADMIN.DASHBOARD.NUTRITIONISTS-INCOME-PER-YEAR",
    people: "ADMIN.DASHBOARD.PEOPLE",
    nodata: "ADMIN.DASHBOARD.NO-DATA",
    top10Departments: "ADMIN.DASHBOARD.TOP-10-DEPARTMENTS",
    top10Medicines: "ADMIN.DASHBOARD.TOP-10-MEDICINES",
    less: "ADMIN.DASHBOARD.LESS",
    more: "ADMIN.DASHBOARD.MORE",
    top10Doctors: "ADMIN.DASHBOARD.TOP-10-DOCTORS",
    topCount: "ADMIN.DASHBOARD.TOP-COUNT",
    TopScore: "ADMIN.DASHBOARD.TOP-SCORE",
    top10Nutrtionists: "ADMIN.DASHBOARD.TOP-10-NUTRTIONISTS",

    doctorDashboard: "ADMIN.DASHBOARDDOCTOR.DASHBOARD-PAGE-DOCTOR-DASHBOARD",
    selectDoctor: "ADMIN.DASHBOARDDOCTOR.SELECT-DOCTOR",
    totalReport: "ADMIN.DASHBOARDDOCTOR.TOTAL-REPORT",
    totalIncome: "ADMIN.DASHBOARDDOCTOR.TOTAL-INCOME",
    totalIncomeMonth: "ADMIN.DASHBOARDDOCTOR.TOTAL-INCOME-MONTH",
    totalDoctorRevenueTotaled:
      "ADMIN.DASHBOARDDOCTOR.TOTAL-DOCTOR-REVENUE-TOTALED",
    baht: "ADMIN.DASHBOARDDOCTOR.BAHT",
    totalCase: "ADMIN.DASHBOARDDOCTOR.TOTAL-CASE",
    caseDoctorRevenueTotaled:
      "ADMIN.DASHBOARDDOCTOR.CASE-DOCTOR-REVENUE-TOTALED",
    rating: "ADMIN.DASHBOARDDOCTOR.RATING",
    ratingonMonth: "ADMIN.DASHBOARDDOCTOR.RATINGONMONTH",
    latestIncome: "ADMIN.DASHBOARDDOCTOR.LATEST-INCOME",
    Report: "ADMIN.DASHBOARDDOCTOR.REPORT",
    totalToday: "ADMIN.DASHBOARDDOCTOR.TOTAL-TODAY",
    totalSales: "ADMIN.DASHBOARDDOCTOR.TOTAL-SALES",
    are: "ADMIN.DASHBOARDDOCTOR.ARE",
    totalWeek: "ADMIN.DASHBOARDDOCTOR.TOTAL-WEEK",
    weekly: "ADMIN.DASHBOARDDOCTOR.WEEKLY",
    total: "ADMIN.DASHBOARDDOCTOR.TOTAL",
    totalMonth: "ADMIN.DASHBOARDDOCTOR.TOTAL-MONTH",
    barChartTotalIncome: "ADMIN.DASHBOARDDOCTOR.BAR-CHART-TOTAL-INCOME",
    caseToday: "ADMIN.DASHBOARDDOCTOR.CASE-TODAY",
    caseWeek: "ADMIN.DASHBOARDDOCTOR.CASE-WEEK",
    caseMonth: "ADMIN.DASHBOARDDOCTOR.CASE-MONTH",
    caseDoctor: "ADMIN.DASHBOARDDOCTOR.CASE-DOCTOR",
    caseDoctors: "ADMIN.DASHBOARDDOCTOR.CASE-DOCTORS",
    doctor: "ADMIN.DASHBOARDDOCTOR.DOCTOR",
    cases: "ADMIN.DASHBOARDDOCTOR.CASES",
    barChartDoctorCase: "ADMIN.DASHBOARDDOCTOR.BAR-CHART-DOCTOR-CASE",

    pharmacyDashboard: "ADMIN.DASHBOARDPHARMACY.PHARMACY-DASHBOARD",
    totalDrugSalesRevenueTotaled:
      "ADMIN.DASHBOARDPHARMACY.TOTAL-DRUG-SALES-REVENUE-TOTALED",
    drugSales: "ADMIN.DASHBOARDPHARMACY.DRUG-SALES",
    orderToday: "ADMIN.DASHBOARDPHARMACY.ORDER-TODAY",
    orderWeek: "ADMIN.DASHBOARDPHARMACY.ORDER-WEEK",
    orderMonth: "ADMIN.DASHBOARDPHARMACY.ORDER-MONTH",
    totalOrder: "ADMIN.DASHBOARDPHARMACY.TOTAL-ORDER",
    selectPharmacy: "ADMIN.DASHBOARDPHARMACY.SELECT-PHARMACY",
    orderDrugSalesRevenueTotaled:
      "ADMIN.DASHBOARDPHARMACY.ORDER-DRUG-SALES-REVENUE-TOTALED",
    totalSalesOfMedicines: "ADMIN.DASHBOARDPHARMACY.TOTAL-SALES-OF-MEDICINES",
    totalDrugSalesRevenueTotaleds:
      "ADMIN.DASHBOARDPHARMACY.TOTAL-DRUG-SALES-REVENUE-TOTALEDS",
    recentOrder: "ADMIN.DASHBOARDPHARMACY.RECENT-ORDER",
    topMeddicine10Item: "ADMIN.DASHBOARDPHARMACY.TOP-MEDDICINE-10-ITEM",
    top10UnpopularDrugs: "ADMIN.DASHBOARDPHARMACY.TOP-10-UNPOPULAR-DRUGS",
    sell: "ADMIN.DASHBOARDPHARMACY.SELL",
    pieces: "ADMIN.DASHBOARDPHARMACY.PIECES",
    orderSalesOfMedicine: "ADMIN.DASHBOARDPHARMACY.ORDER-SALES-OF-MEDICINE",
    orderSalesOfMedicines: "ADMIN.DASHBOARDPHARMACY.ORDER-SALES-OF-MEDICINES",

    nutritionistDashboard: "ADMIN.DASHBOARDNUTRITIONIST.NUTRITIONIST-DASHBOARD",
    selectNutritionist: "ADMIN.DASHBOARDNUTRITIONIST.SELECT-NUTRITIONIST",
    totalNutritionistRevenueTotaled:
      "ADMIN.DASHBOARDNUTRITIONIST.TOTAL-NUTRITIONIST-REVENUE-TOTALED",
    caseNutritionistRevenueTotaled:
      "ADMIN.DASHBOARDNUTRITIONIST.CASE-NUTRITIONIST-REVENUE-TOTALED",
    caseNutritionist: "ADMIN.DASHBOARDNUTRITIONIST.CASE-NUTRITIONIST",
    caseNutritionists: "ADMIN.DASHBOARDNUTRITIONIST.CASE-NUTRITIONISTS",
    barChatNutritionistCase:
      "ADMIN.DASHBOARDNUTRITIONIST.BAR-CHAT-NUTRITIONIST-CASE",

    viewDoctors: "ADMIN.ALLDOCTORS.VIEW-DOCTORS",
    view: "ADMIN.ALLDOCTORS.VIEW",
    doctorList: "ADMIN.ALLDOCTORS.DOCTOR-LIST",
    listDoctorTable: "ADMIN.ALLDOCTORS.LIST-DOCTOR-TABLE",
    search: "ADMIN.ALLDOCTORS.SEARCH",
    image: "ADMIN.ALLDOCTORS.IMAGE",
    name: "ADMIN.ALLDOCTORS.NAME",
    gender: "ADMIN.ALLDOCTORS.GENDER",
    age: "ADMIN.ALLDOCTORS.AGE",
    email: "ADMIN.ALLDOCTORS.EMAIL",
    dateOfBirth: "ADMIN.ALLDOCTORS.DATEOFBIRTH",
    languageProficiency: "ADMIN.ALLDOCTORS.LANGUAGE-PROFICIENCY",
    chatPerTime: "ADMIN.ALLDOCTORS.CHAT-PER-TIME",
    videoPerTime: "ADMIN.ALLDOCTORS.VIDEO-PER-TIME",
    workPlace: "ADMIN.ALLDOCTORS.WORKPLACE",
    education: "ADMIN.ALLDOCTORS.EDUCATION",
    workExperience: "ADMIN.ALLDOCTORS.WORKEXPERIENCE",
    statusOnline: "ADMIN.ALLDOCTORS.STATUS-ONLINE",
    online: "ADMIN.ALLDOCTORS.ONLINE",
    offline: "ADMIN.ALLDOCTORS.OFFLINE",
    actions: "ADMIN.ALLDOCTORS.ACTIONS",
    doctorSkill: "ADMIN.ALLDOCTORS.DOCTOR-SKILL",
    skillDoctor: "ADMIN.ALLDOCTORS.SKILL-DOCTOR",
    skillNameThai: "ADMIN.ALLDOCTORS.SKILL-NAME-THAI",
    skillNameEnglish: "ADMIN.ALLDOCTORS.SKILL-NAME-ENGLISH",
    addSkillDoctor: "ADMIN.ALLDOCTORS.ADD-SKIL-DOCTOR",
    update: "ADMIN.ALLDOCTORS.UPDATE",
    cancel: "ADMIN.ALLDOCTORS.CANCEL",
    addDoctorInformation: "ADMIN.ALLDOCTORS.ADD-DOCTOR-INFORMATION",
    userName: "ADMIN.ALLDOCTORS.USERNAME",
    usernameIsRequired: "ADMIN.ALLDOCTORS.USERNAME-IS-REQUIRED",
    duplicateUsername: "ADMIN.ALLDOCTORS.DUPLICATE-USERNAME",
    emailIsRequired: "ADMIN.ALLDOCTORS.EMAIL-IS-REQUIRED",
    pleaseEnteraValidEmailAddress:
      "ADMIN.ALLDOCTORS.PLEASE-ENTER-A-VALID-EMAIL-ADDRESS",
    duplicateEmail: "ADMIN.ALLDOCTORS.DUPLICATE-EMAIL",
    enterYourPassword: "ADMIN.ALLDOCTORS.ENTER-YOUR-PASSWORD",
    confirmPassword: "ADMIN.ALLDOCTORS.CONFIRM-PASSWORD",
    passwordsAreNotTheSame: "ADMIN.ALLDOCTORS.PASSWORDS-ARE-NOT-THE-SAME",
    prefix: "ADMIN.ALLDOCTORS.PREFIX",
    firstName: "ADMIN.ALLDOCTORS.FIRSTNAME",
    firstNameIsRequired: "ADMIN.ALLDOCTORS.FIRSTNAME-IS-REQUIRED",
    lastName: "ADMIN.ALLDOCTORS.LASTNAME",
    lastNameIsRequired: "ADMIN.ALLDOCTORS.LASTNAME-IS-REQUIRED",
    selectGender: "ADMIN.ALLDOCTORS.SELECT-GENDER",
    male: "ADMIN.ALLDOCTORS.MALE",
    female: "ADMIN.ALLDOCTORS.FEMALE",
    ageIsRequired: "ADMIN.ALLDOCTORS.AGE-IS-REQUIRED",
    birthOfDateIsIequired: "ADMIN.ALLDOCTORS.BRITHOFDATE-IS-REQUIRED",
    taxId: "ADMIN.ALLDOCTORS.TAX-ID",
    taxIdIsRequired: "ADMIN.ALLDOCTORS.TAXID-IS-REQUIRED",
    citizenIdNo: "ADMIN.ALLDOCTORS.CITIZEN-ID-NO",
    citizenIdIsRequired: "ADMIN.ALLDOCTORS.CITIZENID-IS-REQUIRED",
    chatTimePriceIsRequired: "ADMIN.ALLDOCTORS.CHATTIMEPRICE-IS-REQUIRED",
    videoTimePriceIsRequired: "ADMIN.ALLDOCTORS.VIDEOTIMEPRICE-IS-REQUIRED",
    pleaseEnteraValidEducation:
      "ADMIN.ALLDOCTORS.PLEASE-ENTER-A-VALID-EDUCATION",
    pleaseEnteraValidWorkPlace:
      "ADMIN.ALLDOCTORS.PLEASE-ENTER-A-VALID-WORK-PLACE",
    pleaseEnteraValidWorkExperienceYear:
      "ADMIN.ALLDOCTORS.PLEASE-ENTER-A-VALID-WORK-EXPERIENCE-YEAR",
    about: "ADMIN.ALLDOCTORS.ABOUT",
    skils: "ADMIN.ALLDOCTORS.SKILLS",
    next: "ADMIN.ALLDOCTORS.NEXT",
    back: "ADMIN.ALLDOCTORS.BACK",
    previous: "ADMIN.ALLDOCTORS.PREVIOUS",
    add: "ADMIN.ALLDOCTORS.ADD",
    save: "ADMIN.ALLDOCTORS.SAVE",
    pagepage: "ADMIN.ALLDOCTORS.PAGEPAGE",
    pageof: "ADMIN.ALLDOCTORS.PAGEOF",
    delete: "ADMIN.ALLDOCTORS.DELETE",
    chooseAnSkill: "ADMIN.ALLDOCTORS.CHOOSE-AN-SKILL",
    workingTime: "ADMIN.ALLDOCTORS.WORKING-TIME",
    timeStart: "ADMIN.ALLDOCTORS.TIME-START",
    timeEnd: "ADMIN.ALLDOCTORS.TIME-END",
    chooseDayStart: "ADMIN.ALLDOCTORS.CHOOSE-DAY-START",
    chooseDayEnd: "ADMIN.ALLDOCTORS.CHOOSE-DAY-END",
    sunday: "ADMIN.ALLDOCTORS.SUNDAY",
    monday: "ADMIN.ALLDOCTORS.MONDAY",
    tuesday: "ADMIN.ALLDOCTORS.TUESDAY",
    wednesday: "ADMIN.ALLDOCTORS.WEDNESDAY",
    thursday: "ADMIN.ALLDOCTORS.THURSDAY",
    friday: "ADMIN.ALLDOCTORS.FRIDAY",
    saturday: "ADMIN.ALLDOCTORS.SATURDAY",

    viewPharmacy: "ADMIN.ALLPHARMACY.VIEW-PHARMACY",
    pharmacy: "ADMIN.ALLPHARMACY.PHARMACY",
    listPharmacyTable: "ADMIN.ALLPHARMACY.LIST-PHARMACY-TABLE",
    store: "ADMIN.ALLPHARMACY.STORE",
    nameStore: "ADMIN.ALLPHARMACY.NAME-STORE",
    phone: "ADMIN.ALLPHARMACY.PHONE",
    subdistrict: "ADMIN.ALLPHARMACY.SUBDISTRICT",
    namePharmacy: "ADMIN.ALLPHARMACY.NAME-PHARMACY",
    namePharmacyIsRequired: "ADMIN.ALLPHARMACY.NAME-PHARMACY-IS-REQUIRED",
    duplicateNamePharmacy: "ADMIN.ALLPHARMACY.DUPLICATE-NAME-PHARMACY",
    cantFillMoreThan10: "ADMIN.ALLPHARMACY.CANT-FILL-MORE-THAN10",
    numberIsRequired: "ADMIN.ALLPHARMACY.NUMBER-IS-REQUIRED",
    aboutPhamacy: "ADMIN.ALLPHARMACY.ABOUT-PHAMACY",
    aboutPhamacyIsRequired: "ADMIN.ALLPHARMACY.ABOUT-PHAMACY-IS-REQUIRED",
    address: "ADMIN.ALLPHARMACY.ADDRESS",
    doNotEnter: "ADMIN.ALLPHARMACY.DO-NOT-ENTER",
    subdistricts: "ADMIN.ALLPHARMACY.SUB-DISTRICT",
    subArea: "ADMIN.ALLPHARMACY.SUB-AREA",
    district: "ADMIN.ALLPHARMACY.DISTRICT",
    area: "ADMIN.ALLPHARMACY.AREA",
    province: "ADMIN.ALLPHARMACY.PROVINCE",
    pleaseEnteraValidAddress: "ADMIN.ALLPHARMACY.PLEASE-ENTER-A-VALID-ADDRESS",
    zipCode: "ADMIN.ALLPHARMACY.ZIP-CODE",
    zipCodeIsRequired: "ADMIN.ALLPHARMACY.ZIP-CODE-IS-REQUIRED",

    settings: "ADMIN.ALLPHARMACY.SETTINGS",
    account: "ADMIN.ALLPHARMACY.ACCOUNT",
    settingAccount: "ADMIN.ALLPHARMACY.SETTING-ACCOUNT",
    settinPayment: "ADMIN.ALLPHARMACY.SETTING-PAYMENT",
    admin: "ADMIN.ALLPHARMACY.ADMIN",
    pharmacys: "ADMIN.ALLPHARMACY.PHARMACYS",
    location: "ADMIN.ALLPHARMACY.LOCATION",
    allProducts: "ADMIN.ALLPHARMACY.ALL-PRODUCTS",
    list: "ADMIN.ALLPHARMACY.LIST",
    video: "ADMIN.ALLPHARMACY.VIDEO",
    chat: "ADMIN.ALLPHARMACY.CHAT",
    min: "ADMIN.ALLPHARMACY.MIN",
    allOrders: "ADMIN.ALLPHARMACY.ALL-ORDERS",
    recommendedProducts: "ADMIN.ALLPHARMACY.RECOMMENDED-PRODUCTS",

    payment: "ADMIN.ALLPHARMACY.PAYMENT",
    bankName: "ADMIN.ALLPHARMACY.BANKNAME",
    cardCode16Digits: "ADMIN.ALLPHARMACY.CARD-CODE16DIGITS",
    cardCodeIsRequired: "ADMIN.ALLPHARMACY.CARD-CODE-IS-REQUIRED",
    duplicateCardCode: "ADMIN.ALLPHARMACY.DUPLICATE-CARD-CODE",
    accountName: "ADMIN.ALLPHARMACY.ACCOUNTNAME",
    accountNameIsRequired: "ADMIN.ALLPHARMACY.ACCOUNTNAME-IS-REQUIRED",
    duplicateAccountName: "ADMIN.ALLPHARMACY.DUPLICATE-ACCOUNT-NAME",
    editPayment: "ADMIN.ALLPHARMACY.EDIT-PAYMENT",
    cardCode: "ADMIN.ALLPHARMACY.CARDCODE",
    noDataPayment: "ADMIN.ALLPHARMACY.NODATA-PAYMENT",

    viewNutritionist: "ADMIN.ALLNUTRITIONIST.VIEW-NUTRITIONIST",
    nutritionist: "ADMIN.ALLNUTRITIONIST.NUTRITIONIST",
    birthday: "ADMIN.ALLNUTRITIONIST.BIRTHDAY",
    listNutritionistTable: "ADMIN.ALLNUTRITIONIST.LIST-NUTRITIONIST-TABLE",
    addNutritionistInformation:
      "ADMIN.ALLNUTRITIONIST.ADD-NUTRITIONIST-INFORMATION",

    phardeltitle: "ADMIN.ALLPHARMACY.RUSURE",
    phardeltext: "ADMIN.ALLPHARMACY.CONSURE",
    phardelconfirm: "ADMIN.ALLPHARMACY.CONFIRMYES",
    phardelcancel: "ADMIN.ALLPHARMACY.CONFIRMCANCEL",

    locationsettings: "ADMIN.ALLPHARMACY.LOCATIONSETTINGS",
    latitude: "ADMIN.ALLPHARMACY.LATITUDE",
    longitude: "ADMIN.ALLPHARMACY.LONGITUDE",
    updatelocation: "ADMIN.ALLPHARMACY.UPDATELOCATION",

    welcometo: "LOGIN.WELCOMETO",
    username: "LOGIN.USERNAME",
    password: "LOGIN.PASSWORD",
    signin: "LOGIN.SIGNIN",
    usernameisrequired: "LOGIN.USERNAMEISREQUIRED",
    passwordisrequired: "LOGIN.PASSWORDISREQUIRED",

    packageandpromotion: "ADMIN.ALLPACKAGE.PACKAGEANDPROMOTION",
    distributor: "ADMIN.ALLPACKAGE.DISTRIBUTOR",
    distributorlist: "ADMIN.ALLPACKAGE.DISTRIBUTORLIST",
    sub_district: "ADMIN.ALLPHARMACY.SUB-DISTRICT",
    allpackages: "MENUITEMS.DISTRIBUTOR.ALLPACKAGE",
    dttname: "MENUITEMS.DISTRIBUTOR.DTTNAME",
    packagename: "MENUITEMS.DISTRIBUTOR.PACKAGENAME",
    freetele: "MENUITEMS.DISTRIBUTOR.FREETELE",
    telreport: "MENUITEMS.DISTRIBUTOR.TELREPORT",
    price: "DOCTOR.PATIENTS.PRICE",
    packagelist: "ADMIN.ALLPACKAGE.PACKAGELIST",
    dealer: "ADMIN.ALLPACKAGE.DEALER",
    packagedate: "ADMIN.ALLPACKAGE.PACKAGEDATE",
    typepackage: "ADMIN.ALLPACKAGE.TYPEPACKAGE",
    mainpackage: "ADMIN.ALLPACKAGE.MAINPACKAGE",
    secondarypackage: "ADMIN.ALLPACKAGE.SECONDARYPACKAGE",
    auditlicense: "ADMIN.ALLPACKAGE.AUDITLICENSE",
    confirm: "MENUITEMS.DISTRIBUTOR.CONFIRM",
    enable: "MENUITEMS.DISTRIBUTOR.ENABLE",
    disable: "MENUITEMS.DISTRIBUTOR.DISABLE",
    clickenable: "MENUITEMS.DISTRIBUTOR.CLICKENABLE",
    clickdisable: "MENUITEMS.DISTRIBUTOR.CLICKDISABLE",
    okadd: "MENUITEMS.DISTRIBUTOR.OKADD",
    adddistributorsuccess: "ADMIN.ALLPACKAGE.ADDDISTRIBUTORSUCCESS",
    addpackage: "MENUITEMS.DISTRIBUTOR.ADDPACKAGE",
    editpackage: "ADMIN.ALLPACKAGE.EDITPACKAGE",
    detail: "MENUITEMS.DISTRIBUTOR.DETAIL",
    addauditlicense: "ADMIN.ALLPACKAGE.ADDAUDITLICENSE",
    editauditlicense: "ADMIN.ALLPACKAGE.EDITAUDITLICENSE",
    adddistributor: "ADMIN.ALLPACKAGE.ADDDISTRIBUTOR",
    editdistributor: "ADMIN.ALLPACKAGE.EDITDISTRIBUTOR",
    editdealer: "ADMIN.ALLPACKAGE.EDITDEALER",
    auditlicenselist: "ADMIN.ALLPACKAGE.AUDITLICENSELIST",
    nomatch: "ADMIN.ALLPACKAGE.NOMATCH",
    deleterecord: "ADMIN.ALLPACKAGE.DELETERECORD",
    clickdeleterecord: "ADMIN.ALLPACKAGE.CLICKDELETERECORD",
    okdeleterecord: "ADMIN.ALLPACKAGE.OKDELETERECORD",
    okeditrecord: "ADMIN.ALLPACKAGE.OKEDITRECORD",
    dealerlist: "ADMIN.ALLPACKAGE.DEALERLIST",
    audit: "ADMIN.ALLPACKAGE.AUDIT",
    verifyreg: "ADMIN.ALLPACKAGE.VERIFYREG",
    clickverifyreg: "ADMIN.ALLPACKAGE.CLICKVERIFYREG",
    paypertime: "ADMIN.ALLPACKAGE.PAY-PER-TIME",
    autopayment: "ADMIN.ALLPACKAGE.AUTO-PAYMENT",
    entranceFee: "ADMIN.ALLPACKAGE.ENTRANCE-FEE",

    telsupport: "ADMIN.TELSUPPORT.TELSUPPORT",
    telsupportlist: "ADMIN.TELSUPPORT.TELSUPPORTLIST",
    addtelsupport: "ADMIN.TELSUPPORT.ADDTELSUPPORT",
    edittelsupport: "ADMIN.TELSUPPORT.EDITTELSUPPORT",
    removetelsupport: "ADMIN.TELSUPPORT.REMOVETELSUPPORT",
    rusureremove: "ADMIN.TELSUPPORT.RUSUREREMOVE",
    removesuccess: "ADMIN.TELSUPPORT.REMOVESUCCESS",
    addsuccess: "ADMIN.TELSUPPORT.ADDSUCCESS",
    updatesuccess: "ADMIN.TELSUPPORT.UPDATESUCCESS",

    qty: "MENUITEMS.DISTRIBUTOR.QTY",
    packagerequest: "MENUITEMS.DISTRIBUTOR.PACKAGEREQUEST",
    requestdate: "MENUITEMS.DISTRIBUTOR.REQUESTDATE",
    confirmpackagedtt: "ADMIN.ALLPACKAGE.CONFIRMPACKAGEDTT",
    cancelpackagedtt: "ADMIN.ALLPACKAGE.CANCELPACKAGEDTT",

    enterpassword: "ADMIN.ALLPACKAGE.ENTERPASSWORD",

    viewadminviewonly: "ADMIN.ADMINVIEWONLY.VIEW-ADMINVIEWONLY",
    adminviewonly: "ADMIN.ADMINVIEWONLY.ADMINVIEWONLY",
    adminviewonlytable: "ADMIN.ADMINVIEWONLY.ADMINVIEWONLY-TABLE",
    addadminviewonly: "ADMIN.ADMINVIEWONLY.ADD-ADMINVIEWONLY",
    confirmpassword: "ADMIN.ADMINVIEWONLY.CONFIRMPASSWORD",
    ifpassword: "ADMIN.ADMINVIEWONLY.IF-PASSWORD",

    skillenhancer: "ADMIN.ADMINVIEWONLY.SKILLENHANCER",
    year: "DOCTOR.PATIENTS.YEAR",
    skillnamerequired: "DOCTOR.PROFILE.SKILLNAMEREQUIRED",
    datecreate: "MENUITEMS.DISTRIBUTOR.DATECREATE",
    accept: "MENUITEMS.DISTRIBUTOR.ACCEPT",
    decline: "MENUITEMS.DISTRIBUTOR.DECLINE",

    date: 'DOCTOR.PATIENTS.DATE',
    status: 'DOCTOR.PATIENTS.STATUS',
    success: 'ADMIN.SETTING.SUCCESS',
    systemrefund: 'ADMIN.SETTING.SYSTEM-REFUND',
    waitingforrefund: 'ADMIN.SETTING.WAITING-FOR-REFUND',
    refundcashlist: 'ADMIN.SETTING.REFUND-CASH-LIST',
    refundpointlist: 'ADMIN.SETTING.REFUND-POINT-LIST',
    refundtransferlist: 'ADMIN.SETTING.REFUND-TRANSFER-LIST',
    refundtelelist: 'ADMIN.SETTING.REFUND-TELE-LIST',

    refundcash: 'ADMIN.SETTING.REFUND-CASH',
    refundpoint: 'ADMIN.SETTING.REFUND-POINT',
    refundtransfer: 'ADMIN.SETTING.REFUND-TRANSFER',
    refundtele: 'ADMIN.SETTING.REFUND-TELE',
    all: 'ADMIN.SETTING.All',

    moremore: 'AUDIT.USERPACKAGEACTIVATE.MOREMORE',
    user: 'NUTRITIONIST.MENU.USER',
    refund: 'MENUITEMS.MAIN.REFUND',
    note: 'DOCTOR.CHAT.NOTE',
    bank: 'PHARMACY.ORDERDRUGLIST.BANK',
    accountnamee: 'PHARMACY.ORDERDRUGLIST.ACCOUNTNAME',
    accountnumber: 'PHARMACY.ORDERDRUGLIST.ACCOUNTNUMBER',

    delivers: 'ADMIN.DASHBOARD.DELIVERS',
    refunds: 'ADMIN.DASHBOARD.REFUNDS',
    totalDoctors: 'ADMIN.DASHBOARD.TOTALDOCTORS',
    totalPharmacies: 'ADMIN.DASHBOARD.TOTALPHARMACIES',
    totalPatients: 'ADMIN.DASHBOARD.TOTALPATIENTS',
    totalDelivers: 'ADMIN.DASHBOARD.TOTALDELIVERS',
    totalRefunds: 'ADMIN.DASHBOARD.TOTALREFUNDS',
    doctorsOnline: 'ADMIN.DASHBOARD.DOCTORSONLINE',
    doctorAllCases: 'ADMIN.DASHBOARD.DOCTORALLCASES',
    successfulCases: 'ADMIN.DASHBOARD.SUCCESSFULCASES',
    unsuccessfulCases: 'ADMIN.DASHBOARD.UNSUCCESSFULCASES',
    totalDoctorCases: 'ADMIN.DASHBOARD.TOTALDOCTORCASES',
    day: 'ADMIN.DASHBOARD.DAY',
    week: 'ADMIN.DASHBOARD.WEEK',
    month: 'ADMIN.DASHBOARD.MONTH',
    totalRating: 'ADMIN.DASHBOARD.TOTALRATING',
    alldoctorsofsystem: 'ADMIN.DASHBOARD.ALLDOCTORSOFSYSTEM',
    allpharmaciesofsystem: 'ADMIN.DASHBOARD.ALLPHARMACIESOFSYSTEM',
    allpatientsofsystem: 'ADMIN.DASHBOARD.ALLPATIENTSOFSYSTEM',
    alldeliversofsystem: 'ADMIN.DASHBOARD.ALLDELIVERSOFSYSTEM',
    allrefundsofsystem: 'ADMIN.DASHBOARD.ALLREFUNDSOFSYSTEM',
    casess: 'ADMIN.DASHBOARD.CASESS',
    patientResponse: 'ADMIN.DASHBOARD.PATIENTRESPONSE',
    totalDoctorIncome: 'ADMIN.DASHBOARD.TOTALDOCTORINCOME',
    bahtt: 'ADMIN.DASHBOARD.BAHTT',
    daily: 'ADMIN.DASHBOARD.DAILY',
    monthly: 'ADMIN.DASHBOARD.MONTHLY',
    yearly: 'ADMIN.DASHBOARD.YEARLY',
    monthh: 'ADMIN.DASHBOARD.MONTHH',
    yearr: 'ADMIN.DASHBOARD.YEARR',

    executives: 'ADMIN.EXECUTIVES.EXECUTIVES',
    addexecutive: 'ADMIN.EXECUTIVES.ADD-EXECUTIVE',
    editexecutive: 'ADMIN.EXECUTIVES.EDIT-EXECUTIVE',
    edit: 'ADMIN.EXECUTIVES.EDIT',

    usersReport: 'MENUITEMS.EXECUTIVE.LIST.USERS-REPORT',
    downloadExport: 'MENUITEMS.EXECUTIVE.MORE.DOWNLOAD-EXPORT',
    userType: 'MENUITEMS.EXECUTIVE.MORE.USER-TYPE',
    uDoctor: 'MENUITEMS.EXECUTIVE.MORE.DOCTOR',
    uUser: 'MENUITEMS.EXECUTIVE.MORE.USER',
    uAudit: 'MENUITEMS.EXECUTIVE.MORE.AUDIT',
    uDistributor: 'MENUITEMS.EXECUTIVE.MORE.DISTRIBUTOR',
    uDealer: 'MENUITEMS.EXECUTIVE.MORE.DEALER',
    uTelSupport: 'MENUITEMS.EXECUTIVE.MORE.TELSUPPORT',
    showAll: 'MENUITEMS.EXECUTIVE.MORE.SHOW-ALL',
    showAllDoctors: 'MENUITEMS.EXECUTIVE.MORE.SHOW-ALL-DOCTORS',
    startDate: 'MENUITEMS.EXECUTIVE.MORE.START-DATE',
    endDate: 'MENUITEMS.EXECUTIVE.MORE.END-DATE',
    doctorIncomeReport: 'MENUITEMS.EXECUTIVE.LIST.DOCTOR-INCOME-REPORT',
    doctorCaseReport: 'MENUITEMS.EXECUTIVE.LIST.DOCTOR-CASE-REPORT',
    packageOrderReport: 'MENUITEMS.EXECUTIVE.LIST.PACKAGE-ORDER-REPORT',
    transaction: 'MENUITEMS.EXECUTIVE.MORE.TRANSACTION',
    paymentType: 'MENUITEMS.EXECUTIVE.MORE.PAYMENT-TYPE',
    amount: 'MENUITEMS.EXECUTIVE.MORE.AMOUNT',
    appointmentDate: 'MENUITEMS.EXECUTIVE.MORE.APPOINTMENT-DATE',
    uPatient: 'MENUITEMS.EXECUTIVE.MORE.PATIENT',
    symptom: 'MENUITEMS.EXECUTIVE.MORE.SYMPTOM-NAME',
    period: 'MENUITEMS.EXECUTIVE.MORE.PERIOD',
    acceptanceStatus: 'MENUITEMS.EXECUTIVE.MORE.ACCEPTANCE-STATUS',
    paymentStatus: 'MENUITEMS.EXECUTIVE.MORE.PAYMENT-STATUS',
    doctorAcceptYes: 'MENUITEMS.EXECUTIVE.MORE.DOCTOR-ACCEPT-YES',
    doctorAcceptNo: 'MENUITEMS.EXECUTIVE.MORE.DOCTOR-ACCEPT-NO',
    doctorAcceptWait: 'MENUITEMS.EXECUTIVE.MORE.DOCTOR-ACCEPT-WAIT',
    userPayYes: 'MENUITEMS.EXECUTIVE.MORE.USER-PAY-YES',
    userPayNo: 'MENUITEMS.EXECUTIVE.MORE.USER-PAY-NO',
    userPayWait: 'MENUITEMS.EXECUTIVE.MORE.USER-PAY-WAIT',
    customerName: 'MENUITEMS.EXECUTIVE.MORE.CUSTOMER-NAME',
    packageType: 'MENUITEMS.EXECUTIVE.MORE.PACKAGE-TYPE',
    packageName: 'MENUITEMS.EXECUTIVE.MORE.PACKAGE-NAME',
    ref: 'MENUITEMS.EXECUTIVE.MORE.REF',
    paymentDate: 'MENUITEMS.EXECUTIVE.MORE.PAYMENT-DATE',
    totalDoctor: 'MENUITEMS.EXECUTIVE.MORE.TOTAL-DOCTOR',
    totalPatient: 'MENUITEMS.EXECUTIVE.MORE.TOTAL-PATIENT',
    totalPharmacy: 'MENUITEMS.EXECUTIVE.MORE.TOTAL-PHARMACY',

    nttns: 'MENUITEMS.EXECUTIVE.MORE.NTTNS',
    audits: 'MENUITEMS.EXECUTIVE.MORE.AUDITS',
    distributors: 'MENUITEMS.EXECUTIVE.MORE.DISTRIBUTORS',
    dealers: 'MENUITEMS.EXECUTIVE.MORE.DEALERS',
    telSupports: 'MENUITEMS.EXECUTIVE.MORE.TELSUPPORTS',
    orders: 'MENUITEMS.EXECUTIVE.MORE.ORDERS',
    totalNttn: 'MENUITEMS.EXECUTIVE.MORE.TOTAL-NTTN',
    totalAudit: 'MENUITEMS.EXECUTIVE.MORE.TOTAL-AUDIT',
    totalDistributor: 'MENUITEMS.EXECUTIVE.MORE.TOTAL-DISTRIBUTOR',
    totalDealer: 'MENUITEMS.EXECUTIVE.MORE.TOTAL-DEALER',
    totalTelSupport: 'MENUITEMS.EXECUTIVE.MORE.TOTAL-TELSUPPORT',
    totalDeliver: 'MENUITEMS.EXECUTIVE.MORE.TOTAL-DELIVER',
    totalDoctorNPatient: 'MENUITEMS.EXECUTIVE.MORE.TOTAL-DOCTOR-N-PATIENT',
    totalUser: 'MENUITEMS.EXECUTIVE.MORE.TOTAL-USER',
    totalDoctorOnline: 'MENUITEMS.EXECUTIVE.MORE.TOTAL-DOCTOR-ONLINE',

    auditLicenses: 'MENUITEMS.HOME.LIST.AUDIT-LICENSES',
    users: 'MENUITEMS.USERS.USERS',
    addUser: 'MENUITEMS.USERS.ADD-USER',
    editUser: 'MENUITEMS.USERS.EDIT-USER',
    relativePhone: 'MENUITEMS.USERS.RELATIVE-PHONE',
    bloodType: 'MENUITEMS.USERS.BLOOD-TYPE',
    weight: 'MENUITEMS.USERS.WEIGHT',
    height: 'MENUITEMS.USERS.HEIGHT',
  },
];
