<div>
  <!-- Left Sidebar -->
  <!-- <aside id="leftsidebar" class="sidebar" (mouseenter)="mouseHover($event)" (mouseleave)="mouseOut($event)"> -->
  <aside id="leftsidebar" class="sidebar custom_sidbar">
    <!-- Menu -->
    <div class="menu">
      <ul class="list"
        [ngStyle]="{'position' : 'relative', 'max-height' : listMaxHeight+'px', 'max-width' : listMaxWidth+'px'}"
        [perfectScrollbar]>
        <li class="sidebar-user-panel">
          <!-- routerLink="doctor/menu/{{id}}" -->
          <!-- <div style="text-align: center; margin-top: 20px;">
            <img src="../../../assets/images/logo_sidebar.png" alt="" width="150px" height="100%">
          </div> -->
          <div class="logo-text mt-2">
            <span style="color: rgba(249, 77, 1, 1);">RE</span>
            <span style="color: #575757;">LEEP</span>
          </div>
        </li>
        <li class="sidebar-user-panel" style="margin-top: -1rem;">
          <div class="user-panel">
            <div class=" image">
              <img src="{{urlPath}}/{{userImg}}" class="img-circle user-img-circle" alt="User Image"
                style="width: 130px; height: 155px; margin-left: -25px; object-fit: cover;" *ngIf="userType != 'Admin'"
                onerror="this.src='assets/images/imgdoctor/nouser.svg'" />
              <img src="assets/images/imgdoctor/nouser.svg" class="img-circle user-img-circle" alt="User Image"
                style="width: 130px; height: 155px; margin-left: -25px; object-fit: cover;"
                *ngIf="userType == 'Admin'" />
            </div>
          </div>
          <div class="profile-usertitle">
            <div class="sidebar-userpic-name" style="font-size: 16px; color: #F94D01; font-weight: bold;"
              *ngIf="userType == 'Doctor' && userDoc != null">
              {{userDoc.drFname}}
              {{userDoc.drLname}}
            </div>
            <div class="sidebar-userpic-name" style="font-size: 16px; color: #F94D01; font-weight: bold;"
              *ngIf="userType == 'Pharmacy'">
              {{userPharmacys.phaR_Name}}</div>
            <div class="sidebar-userpic-name" style="font-size: 16px; color: #F94D01; font-weight: bold;"
              *ngIf="userType == 'Admin' || userType == 'Distributor' || userType == 'Dealer' || userType == 'Audit' || userType == 'Packer' || userType == 'Executive'">
              {{userFullName}} </div>
            <div class="profile-usertitle-job" style="font-size: 14px;color: #0000007b;">{{callNameRole}} </div>
          </div>
        </li>
        <!-- <li class="sidebar-user-panel" *ngIf="this.dataAppointmentsCalendar != null && ckeckShowCalendar == true">
          <div class="card card-calendar"
            *ngIf="this.id == this.dataAppointmentsCalendar[0].drId && this.dataAppointmentsCalendar[0].convoIsTalking == true">
            <span style="display: -webkit-box;" (click)="openCalendar()">
              <img class="screen-size-img-user" src="{{urlPathUser}}{{this.dataAppointmentsCalendar[0].userPic}}"
                style="height: 97px; width: 75px;object-fit: cover;border-radius: 15px;padding: 5px;"
                onerror="this.src='assets/images/imgdoctor/nouser.svg'" alt="img-user">
              <div style="line-height: 4px;margin-top: 10px;margin-left: 10px;">
                <p><i class="far fa-user"></i> {{this.dataAppointmentsCalendar[0].userName}}</p>
                <p>
                  <i class="far fa-calendar-alt"></i>
                  {{this.dataAppointmentsCalendar[0].convoDateAppointment | date:'dd/MM/YYYY'}}
                </p>
                <p><i class="far fa-clock"></i> {{countdownNowCalendar}} นาที</p>
              </div>
            </span>
          </div>
        </li> -->
        <!-- Top Most level menu -->
        <li [class.active]="level1Menu === sidebarItem.moduleName && sidebarItem.submenu.length != 0"
          *ngFor="let sidebarItem of sidebarItems" [routerLinkActive]="sidebarItem.submenu.length != 0 ? '' : 'active'">
          <div class="header" *ngIf="sidebarItem.groupTitle === true"></div>
          <!-- <div class="header" *ngIf="sidebarItem.groupTitle === true">{{sidebarItem.title | translate}}</div> -->
          <a [routerLink]="sidebarItem.class === '' ? [sidebarItem.path +'/'+id] : null"
            *ngIf="!sidebarItem.groupTitle;" [ngClass]="[sidebarItem.class]"
            (click)="callLevel1Toggle($event, sidebarItem.moduleName)" class="menu-top">
            <i [ngClass]="[sidebarItem.iconType]">{{sidebarItem.icon}}</i>
            <span class="hide-menu">{{sidebarItem.title | translate}}
            </span>
            <span *ngIf="sidebarItem.badge != '' && sidebarItem.badge != 0">
              <span matBadge="{{sidebarItem.badge}}" matBadgeColor="warn" [ngClass]="[sidebarItem.badgeClass]">&nbsp;
              </span>
            </span>
          </a>
          <!-- First level menu -->
          <ul class="ml-menu" *ngIf="sidebarItem.submenu.length > 0">
            <li [ngClass]="level2Menu === sidebarSubItem.moduleName? 'activeSub':''"
              *ngFor="let sidebarSubItem of sidebarItem.submenu"
              [routerLinkActive]="sidebarSubItem.submenu.length > 0 ? '' : 'active'">
              <a [routerLink]="sidebarSubItem.submenu.length > 0 ? null : [sidebarSubItem.path]"
                (click)="callLevel2Toggle($event,sidebarSubItem.moduleName)" [ngClass]="[sidebarSubItem.class]">
                {{sidebarSubItem.title | translate}}
              </a>
              <!-- Second level menu -->
              <ul class="ml-menu-2" *ngIf="sidebarSubItem.submenu.length > 0">
                <li *ngFor="let sidebarSubsubItem of sidebarSubItem.submenu"
                  [ngClass]="level3Menu === sidebarSubsubItem.moduleName? 'activeSubSub':''"
                  [routerLinkActive]="sidebarSubsubItem.submenu.length > 0 ? '' : 'active'">
                  <a [routerLink]="sidebarSubsubItem.submenu.length > 0 ? null : [sidebarSubsubItem.path]"
                    (click)="callLevel3Toggle($event,sidebarSubsubItem.moduleName)"
                    [ngClass]="[sidebarSubsubItem.class]">
                    {{sidebarSubsubItem.title | translate}}
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li *ngIf="userType == 'AdminViewOnly'" style="cursor: pointer;">
          <a class="menu-top" (click)="adminSetting()">
            <i class="material-icons-two-tone">settings</i>
            <span class="hide-menu">Admin Setting</span>
          </a>
        </li>
        <li style="cursor: pointer;">
          <a class="" (click)="logout()" class="menu-top">
            <i class="material-icons-two-tone">power_settings_new</i>
            <span class="hide-menu">{{languageHeader.logout | translate}}
            </span>
          </a>
        </li>
      </ul>
      <!-- <footer class="bg-light text-center text-lg-start">
        <div class="text-center p-3" style="background-color: rgba(0, 0, 0, 0.2);">
          © 2020 Copyright:
        </div>
      </footer> -->
    </div>
    <!-- #Menu -->
  </aside>
  <!-- #END# Left Sidebar -->
</div>